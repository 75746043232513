import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
	Outlet,
	RouterProvider,
	createRootRoute,
	createRoute,
	createRouter,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AppLayout } from "./AppLayout";
import { IndexPage } from "./IndexPage";
import { queryClient } from "./client";
import "antd/dist/reset.css";
import { App } from "antd";
import { LocationsPage } from "./LocationsPage";
import { RequestsPage } from "./RequestsPage";
import { Test } from "./Test";
import { UsersPage } from "./UsersPage";

const rootRoute = createRootRoute({
	component: () => (
		<QueryClientProvider client={queryClient}>
			<AppLayout>
				<App>
					<Outlet />
				</App>
			</AppLayout>
			<TanStackRouterDevtools />
			<ReactQueryDevtools />
		</QueryClientProvider>
	),
});

const indexRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/",
	component: IndexPage,
});
const requestsRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/requests",
	component: RequestsPage,
});
const locationsRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/locations",
	component: LocationsPage,
});
const usersRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/users",
	component: UsersPage,
});

const routeTree = rootRoute.addChildren([
	createRoute({
		getParentRoute: () => rootRoute,
		path: "/test",
		component: Test,
	}),
	indexRoute,
	requestsRoute,
	locationsRoute,
	usersRoute,
]);
const router = createRouter({ routeTree });
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}
export const AppRouter = () => <RouterProvider router={router} />;
