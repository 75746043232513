import { Form, Input, Select } from "antd";
import type { NamePath } from "antd/es/form/interface";
import type { ReactNode } from "react";
import { type AnyObject, type ObjectSchema, reach } from "yup";

const getRule = <T extends AnyObject>(
	schema: ObjectSchema<T>,
	getFieldsValue: () => T,
) => ({
	// biome-ignore lint/suspicious/noExplicitAny: CBB
	async validator({ field }: any) {
		await schema.validateSyncAt(field, getFieldsValue());
	},
});

const getFieldSpec = <T extends AnyObject>(
	schema: ObjectSchema<T>,
	name: Extract<NamePath<T>, string>,
) => {
	const result = reach(schema, name);
	if ("spec" in result === false) throw new Error("Invalid schema");
	if ("type" in result === false) throw new Error("Invalid schema");
	return [
		result.spec as ObjectSchema<T>["spec"],
		result.type as string,
	] as const;
};

type FormItemProps<T extends AnyObject> = {
	schema: ObjectSchema<T>;
	name: Extract<NamePath<T>, string>;
	options?: {
		value: string;
		label: string;
	}[];
	hidden?: boolean;
	children?: ReactNode;
};
export const FormItem = <T extends AnyObject>({
	schema,
	name,
	hidden,
	options,
	children,
}: FormItemProps<T>) => {
	const form = Form.useFormInstance();
	const rule = getRule(schema, form.getFieldsValue);
	const [{ label, optional }, type] = getFieldSpec(schema, name);
	return (
		<Form.Item<T>
			label={label}
			name={name}
			rules={[rule]}
			required={!optional}
			hidden={hidden}
		>
			{GuessInput({ children, options, type })}
		</Form.Item>
	);
};

const GuessInput = ({
	children,
	options,
	type,
}: {
	children?: ReactNode;
	options?: { value: string; label: string }[];
	type: string;
}) => {
	if (children) return children;
	if (options)
		return <Select options={options} optionFilterProp="label" showSearch />;
	if (type === "string") return <Input />;
	if (type === "date") return <Input type="datetime-local" />;
	return null;
};
