import { Modal } from "antd";
import type { ReactElement, ReactNode } from "react";
import { useCounter, useToggle, useUpdateEffect } from "react-use";
import type { AnyObject } from "yup";
import { portalRoot } from ".";

export type BaseFormProps<T extends AnyObject> = {
	item?: Partial<T>;
	onFinish: (item: T) => void;
};

type useModalFormProps = {
	children: ReactElement;
	title: ReactNode;
};
export const useModal = ({ children, title }: useModalFormProps) => {
	const [open, toggleOpen] = useToggle(false);
	const [key, { inc }] = useCounter();
	const modalElement = (
		<Modal
			key={key}
			title={title}
			open={open}
			footer={null}
			onCancel={() => toggleOpen(false)}
			afterOpenChange={(x) => x || inc()}
		>
			{children}
		</Modal>
	);
	useUpdateEffect(() => portalRoot.render(modalElement));
	return toggleOpen;
};
