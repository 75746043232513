import dayjs from "dayjs";
import type { CoolTableColumnType } from "./CoolTable";
import type { LocationListItem, RequestListItem } from "./client";

type ObjectWithSpecificKey<K extends string, V> = {
	[key in K]: V | null | undefined;
};

const stringSorter =
	<T extends ObjectWithSpecificKey<K, string>, K extends string>(key: K) =>
	(a: T, b: T) =>
		(a[key] ?? "").localeCompare(b[key] ?? "");

const dateSorter =
	<T extends ObjectWithSpecificKey<K, Date>, K extends string>(key: K) =>
	(a: T, b: T) =>
		(a[key]?.getTime() ?? 0) - (b[key]?.getTime() ?? 0);

const numberSorter =
	<T extends ObjectWithSpecificKey<K, number>, K extends string>(key: K) =>
	(a: T, b: T) =>
		(a[key] ?? 0) - (b[key] ?? 0);

export const requestColumns: CoolTableColumnType<RequestListItem> = [
	{
		title: "Status",
		dataIndex: "status",
		sorter: stringSorter("status"),
		width: 90,
	},
	{
		title: "Description",
		dataIndex: "description",
		sorter: stringSorter("description"),
	},
	{
		title: "Start At",
		dataIndex: "startAt",
		sorter: dateSorter("startAt"),
		render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm"),
		width: 130,
	},
	{
		title: "Start Location",
		dataIndex: "startLocationName",
		sorter: stringSorter("startLocationName"),
	},
	{
		title: "End At",
		dataIndex: "endAt",
		sorter: dateSorter("endAt"),
		render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm"),
		width: 130,
	},
	{
		title: "End Location",
		dataIndex: "endLocationName",
		sorter: stringSorter("endLocationName"),
	},
	{
		title: "Order Number",
		dataIndex: "orderNumber",
		sorter: stringSorter("orderNumber"),
		width: 80,
	},
	{
		title: "Price",
		dataIndex: "price",
		sorter: numberSorter("price"),
		width: 80,
	},
	{
		title: "Reason",
		dataIndex: "reason",
		sorter: stringSorter("reason"),
	},
	{
		title: "Author",
		dataIndex: "userName",
		sorter: stringSorter("userName"),
		width: 80,
	},
];

export const locationColumns: CoolTableColumnType<LocationListItem> = [
	{
		title: "Name",
		dataIndex: "name",
		sorter: stringSorter("name"),
	},
	{
		title: "Address",
		dataIndex: "address",
		sorter: stringSorter("address"),
	},
];
