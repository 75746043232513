import { treaty } from "@elysiajs/eden";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryClient } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { App } from "antd";
import type { Server } from "../../server";
import { authStore } from "./authStore";

const persister = createSyncStoragePersister({ storage: localStorage });
export const queryClient = new QueryClient();
persistQueryClient({ queryClient, persister });

const isLocalhost = location.hostname === "localhost";
const domain = isLocalhost ? "localhost:3000" : location.hostname;
const client = treaty<Server>(domain, {
	fetch: { credentials: "include" },
});

// biome-ignore lint/suspicious/noExplicitAny: CBB
type ExtractType<T extends (...args: any) => any> = NonNullable<
	Awaited<ReturnType<T>>["data"]
>;

export type RequestListItem = ExtractType<
	typeof client.api.requests.get
>[number];
export const useQueryRequests = () =>
	useQuery({
		queryKey: ["requests"],
		queryFn: () =>
			client.api.requests.get().then(
				(x) =>
					x.data?.map((x) => ({
						...x,
						startAt: new Date(x.startAt),
						endAt: new Date(x.endAt),
					})) ?? [],
			),
		initialData: [],
	});

export type LocationListItem = ExtractType<
	typeof client.api.locations.get
>[number];
export const useQueryLocations = () =>
	useQuery({
		queryKey: ["locations"],
		queryFn: () => client.api.locations.get().then((x) => x.data ?? []),
		initialData: [],
	});

export type LocationLookupItem = ExtractType<
	typeof client.api.locationsLookup.get
>[number];
export const useQueryLocationLookup = () =>
	useQuery({
		queryKey: ["locationsLookup"],
		queryFn: () => client.api.locationsLookup.get().then((x) => x.data ?? []),
		initialData: [],
	});

export type UserListItem = ExtractType<typeof client.api.users.get>[number];
export const useQueryUsers = () =>
	useQuery({
		queryKey: ["users"],
		queryFn: () => client.api.users.get().then((x) => x.data ?? []),
		initialData: [],
	});

type LoginBody = Parameters<typeof client.api.login.post>[0];
export type LoginData = ExtractType<typeof client.api.login.post>;
export const useMutationAuth = () => {
	const { notification } = App.useApp();
	const login = useMutation({
		mutationKey: ["auth", "login"],
		mutationFn: (body: LoginBody) =>
			client.api.login.post(body).then((x) => {
				authStore.set(x.data);
				return x.data;
			}),
		onError: (x) =>
			notification.error({
				message: `...Failed login! ${x.message}`,
				key: "auth",
			}),
		onMutate: () =>
			notification.info({
				message: "Logging in...",
				key: "auth",
			}),
		onSuccess: () =>
			notification.success({
				message: "...Logged in!",
				key: "auth",
			}),
	});

	const logout = useMutation({
		mutationKey: ["auth", "logout"],
		mutationFn: () =>
			client.api.logout.get().then((x) => {
				authStore.set(null);
				return x.data;
			}),
		onError: (x) =>
			notification.error({
				message: `...Failed logout! ${x.message}`,
				key: "auth",
			}),
		onMutate: () =>
			notification.info({
				message: "Logging out...",
				key: "auth",
			}),
		onSuccess: () =>
			notification.success({
				message: "...Logged out!",
				key: "auth",
			}),
	});
	return { login, logout };
};

type RequestUpsertBody = Parameters<typeof client.api.requestUpsert.post>[0];
export const useMutationRequestUpsert = () => {
	const { notification } = App.useApp();
	return useMutation({
		mutationKey: ["requestUpsert"],
		mutationFn: (body: RequestUpsertBody) =>
			client.api.requestUpsert.post(body).then((x) => x.data),
		onError: (x) =>
			notification.error({
				message: `...Failed to save Request! ${x.message}`,
				key: "requestUpsert",
			}),
		onMutate: () =>
			notification.info({
				message: "Saving Request...",
				key: "requestUpsert",
			}),
		onSuccess: () => {
			notification.success({
				message: "...Saved Request!",
				key: "requestUpsert",
			});
			queryClient.invalidateQueries({ queryKey: ["requests"] });
		},
	});
};

type LocationUpsertBody = Parameters<typeof client.api.locationUpsert.post>[0];
export const useMutationLocationUpsert = () => {
	const { notification } = App.useApp();
	return useMutation({
		mutationKey: ["locationUpsert"],
		mutationFn: (body: LocationUpsertBody) =>
			client.api.locationUpsert.post(body).then((x) => x.data),
		onError: (x) =>
			notification.error({
				message: `...Failed to save Location! ${x.message}`,
				key: "locationUpsert",
			}),
		onMutate: () =>
			notification.info({
				message: "Saving Location...",
				key: "locationUpsert",
			}),
		onSuccess: () => {
			notification.success({
				message: "...Saved Location!",
				key: "locationUpsert",
			});
			queryClient.invalidateQueries({ queryKey: ["locations"] });
		},
	});
};
