import { CoolTable, type CoolTableColumnType } from "./CoolTable";
import { type UserListItem, useQueryUsers } from "./client";

const columns: CoolTableColumnType<UserListItem> = [
	{
		title: "Name",
		dataIndex: "name",
		sorter: (a, b) => a.name.localeCompare(b.name),
	},
	{
		title: "Groups",
		dataIndex: "groups",
		sorter: (a, b) => a.groups.join().localeCompare(b.groups.join()),
		render: (groups) => groups.join(", "),
	},
	{
		title: "Tenant",
		dataIndex: "tenantName",
		sorter: (a, b) => (a.tenantName ?? "").localeCompare(b.tenantName ?? ""),
	},
];

export const UsersPage = () => {
	const data = useQueryUsers();
	return (
		<CoolTable
			columns={columns}
			dataSource={data.data}
			rowKey="id"
			size="small"
			bordered
			searcheable
			ellipsis
		/>
	);
};
