import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { CoolTable } from "./CoolTable";
import { RequestForm, type RequestFormItem } from "./RequestForm";
import {
	useMutationRequestUpsert,
	useQueryLocationLookup,
	useQueryRequests,
} from "./client";
import { requestColumns } from "./columns";
import { useModal } from "./useModal";

export const RequestsPage = () => {
	const data = useQueryRequests();
	const locations = useQueryLocationLookup();
	const upsertRequest = useMutationRequestUpsert();
	const [item, setItem] = useState<RequestFormItem>();
	const toggleFormModal = useModal({
		children: (
			<RequestForm
				item={item}
				locations={locations.data}
				onFinish={async (x) => {
					await upsertRequest.mutateAsync(x);
					toggleFormModal(false);
				}}
			/>
		),
		title: "Create Request",
	});
	return (
		<CoolTable
			columns={requestColumns}
			dataSource={data.data}
			rightActions={
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => {
						setItem(undefined);
						toggleFormModal(true);
					}}
				>
					Create
				</Button>
			}
			rowActions={{
				title: "Actions",
				dataIndex: "id",
				render: (_, item) => (
					<Button
						type="link"
						size="small"
						onClick={() => {
							setItem(item);
							toggleFormModal(true);
						}}
					>
						Edit
					</Button>
				),
				width: 80,
			}}
		/>
	);
};
