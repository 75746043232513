import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { CoolTable } from "./CoolTable";
import { LocationForm, type LocationFormItem } from "./LocationForm";
import { useMutationLocationUpsert, useQueryLocations } from "./client";
import { locationColumns } from "./columns";
import { useModal } from "./useModal";

export const LocationsPage = () => {
	const [item, setItem] = useState<LocationFormItem>();
	const upsertLocation = useMutationLocationUpsert();
	const toggleFormModal = useModal({
		children: (
			<LocationForm
				item={item}
				onFinish={async (x) =>
					await upsertLocation
						.mutateAsync(x)
						.finally(() => toggleFormModal(false))
				}
			/>
		),
		title: "Create Request",
	});
	const data = useQueryLocations();
	return (
		<CoolTable
			columns={locationColumns}
			dataSource={data.data}
			rowKey="id"
			size="small"
			bordered
			searcheable
			ellipsis
			rightActions={
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => {
						setItem(undefined);
						toggleFormModal(true);
					}}
				>
					Create
				</Button>
			}
			rowActions={{
				title: "Actions",
				dataIndex: "id",
				render: (_, item) => (
					<Button
						type="link"
						size="small"
						onClick={() => {
							setItem(item);
							toggleFormModal(true);
						}}
					>
						Edit
					</Button>
				),
				width: 80,
			}}
		/>
	);
};
