import { Form } from "antd";
import dayjs from "dayjs";
import { type InferType, date, object, string } from "yup";
import { FormButtons } from "./FormButtons";
import { FormItem } from "./FormItem";
import type { LocationLookupItem } from "./client";
import type { BaseFormProps } from "./useModal";

const schema = object({
	id: string().label("ID"),
	description: string().required().label("Description").default("TBC"),
	orderNumber: string().required().label("Order Number").default("TBC"),
	startLocationId: string()
		.required()
		.label("Start Location")
		.default(undefined),
	startAt: date()
		.required()
		.label("Start At")
		.default(() => dayjs().startOf("h").toDate()),
	endLocationId: string().required().label("End Location").default(undefined),
	endAt: date()
		.required()
		.label("End At")
		.default(() => dayjs().startOf("h").add(5, "h").toDate()),
});
export type RequestFormItem = InferType<typeof schema>;

type RequestFormProps = BaseFormProps<RequestFormItem> & {
	locations: LocationLookupItem[];
};
export const RequestForm = ({
	item,
	onFinish,
	locations,
}: RequestFormProps) => {
	const [form] = Form.useForm<RequestFormItem>();
	item ??= schema.cast({}, { assert: false, stripUnknown: true });
	const initialValues = {
		...item,
		startAt: item.startAt ? dayjs(item.startAt).format("YYYY-MM-DDTHH:mm") : "",
		endAt: item.endAt ? dayjs(item.endAt).format("YYYY-MM-DDTHH:mm") : "",
	};
	const handleFinish = (values: RequestFormItem) => {
		const startAt = dayjs(values.startAt).toDate();
		const endAt = dayjs(values.endAt).toDate();
		onFinish({ ...values, startAt, endAt });
	};
	return (
		<Form
			form={form}
			initialValues={initialValues}
			onFinish={handleFinish}
			labelCol={{ span: 6 }}
		>
			<FormItem schema={schema} name="id" hidden />
			<FormItem schema={schema} name="description" />
			<FormItem schema={schema} name="orderNumber" />
			<FormItem schema={schema} name="startLocationId" options={locations} />
			<FormItem schema={schema} name="startAt" />
			<FormItem schema={schema} name="endLocationId" options={locations} />
			<FormItem schema={schema} name="endAt" />
			<FormButtons />
		</Form>
	);
};
