import { logger } from "@nanostores/logger";
import { persistentAtom } from "@nanostores/persistent";
import SuperJSON from "superjson";
import type { LoginData } from "./client";

export const authStore = persistentAtom<LoginData | null>("auth", null, {
	encode: SuperJSON.stringify,
	decode: SuperJSON.parse,
});
logger({ authStore });
