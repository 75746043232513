import { Button, Flex } from "antd";

export const FormButtons = () => {
	return (
		<Flex gap={8} justify="end">
			<Button type="default" htmlType="reset">
				Reset
			</Button>
			<Button type="primary" htmlType="submit">
				Submit
			</Button>
		</Flex>
	);
};
