import { RequestForm } from "./RequestForm";

export const Test = () => {
	return (
		<RequestForm
			locations={[]}
			item={{
				description: "description",
				orderNumber: "orderNumber",
				startLocationId: "startLocationId",
				startAt: new Date(),
				endLocationId: "endLocationId",
				endAt: new Date(),
			}}
			onFinish={(x) => {
				console.log(x);
			}}
		/>
	);
};
