import { createRoot } from "react-dom/client";
import { AppRouter } from "./AppRouter";

const root = document.getElementById("root");
if (!root) throw new Error("Root element not found");
createRoot(root).render(<AppRouter />);

const portal = document.getElementById("portal");
if (!portal) throw new Error("Portal element not found");
export const portalRoot = createRoot(portal);
