import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input } from "antd";
import { useState } from "react";
import { type InferType, object, string } from "yup";
import { FormItem } from "./FormItem";

const schema = object({
	username: string().required().label("Username"),
	password: string().required().label("Password"),
});
type LoginFormItem = InferType<typeof schema>;

type LoginFormProps = { onFinish: (x: LoginFormItem) => Promise<unknown> };
export const LoginForm = ({ onFinish }: LoginFormProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm<LoginFormItem>();
	const handleFinish = async (values: LoginFormItem) => {
		setIsLoading(true);
		await onFinish(values).finally(() => setIsLoading(false));
	};
	return (
		<Form
			form={form}
			onFinish={handleFinish}
			layout="vertical"
			requiredMark={false}
		>
			<FormItem schema={schema} name="username">
				<Input prefix={<UserOutlined />} />
			</FormItem>
			<FormItem schema={schema} name="password">
				<Input prefix={<LockOutlined />} type="password" />
			</FormItem>
			<Flex justify="center">
				<Button type="primary" htmlType="submit" loading={isLoading}>
					Log in
				</Button>
			</Flex>
		</Form>
	);
};
