import { useStore } from "@nanostores/react";
import { useLocation, useNavigate } from "@tanstack/react-router";
import { Layout, Menu } from "antd";
import type { ReactNode } from "react";
import { authStore } from "./authStore";

type AppLayoutProps = { children: ReactNode };
export const AppLayout = ({ children }: AppLayoutProps) => {
	const pathname = useLocation({ select: (x) => x.pathname });
	const navigate = useNavigate();
	const auth = useStore(authStore);
	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Layout.Header style={{ display: "flex", alignItems: "center" }}>
				<div className="demo-logo" />
				<Menu
					theme="dark"
					mode="horizontal"
					selectedKeys={[pathname]}
					items={[
						{
							key: "/",
							label: "Home",
							onClick: () => navigate({ to: "/" }),
						},
						{
							key: "/requests",
							label: "Requests",
							onClick: () => navigate({ to: "/requests" }),
							disabled: auth == null,
						},
						{
							key: "/locations",
							label: "Locations",
							onClick: () => navigate({ to: "/locations" }),
							disabled: auth == null,
						},
						{
							key: "/users",
							label: "Users",
							onClick: () => navigate({ to: "/users" }),
							disabled:
								auth == null ||
								!auth.groups.includes("Admin") ||
								!auth.groups.includes("Root"),
						},
					]}
					style={{ flex: 1, minWidth: 0 }}
				/>
			</Layout.Header>
			<Layout.Content
				style={{
					minHeight: 280,
					padding: 24,
				}}
			>
				{children}
			</Layout.Content>
			<Layout.Footer style={{ textAlign: "center" }}>
				Celerum ©2024
			</Layout.Footer>
		</Layout>
	);
};
