import { Form } from "antd";
import { type InferType, object, string } from "yup";
import { FormButtons } from "./FormButtons";
import { FormItem } from "./FormItem";
import type { BaseFormProps } from "./useModal";

const schema = object({
	id: string().label("ID"),
	name: string().required().label("Name").default("TBC"),
	address: string().required().label("Address").default("TBC"),
});
export type LocationFormItem = InferType<typeof schema>;

type LocationFormProps = BaseFormProps<LocationFormItem>;
export const LocationForm = ({ item, onFinish }: LocationFormProps) => {
	const [form] = Form.useForm<LocationFormItem>();
	item ??= schema.cast({}, { assert: false, stripUnknown: true });
	const initialValues = {
		...item,
	};
	const handleFinish = (values: LocationFormItem) => {
		onFinish({ ...values });
	};
	return (
		<Form
			form={form}
			initialValues={initialValues}
			onFinish={handleFinish}
			labelCol={{ span: 4 }}
		>
			<FormItem schema={schema} name="id" hidden />
			<FormItem schema={schema} name="name" />
			<FormItem schema={schema} name="address" />
			<FormButtons />
		</Form>
	);
};
