import { useStore } from "@nanostores/react";
import { Button, Card, Flex, Space } from "antd";
import { LoginForm } from "./LoginForm";
import { authStore } from "./authStore";
import { useMutationAuth } from "./client";

export const IndexPage = () => {
	const { login, logout } = useMutationAuth();
	const auth = useStore(authStore);
	return (
		<Flex justify="center">
			<Card title="Login" style={{ width: "300px" }}>
				{auth == null ? (
					<LoginForm
						onFinish={async (x) =>
							await login.mutateAsync({
								name: x.username,
								password: x.password,
							})
						}
					/>
				) : (
					<Space direction="vertical">
						<div>
							Welcome <b>{auth.name}</b>!
						</div>
						<Button onClick={() => logout.mutate()} loading={logout.isPending}>
							Logout
						</Button>
					</Space>
				)}
			</Card>
		</Flex>
	);
};
