import type { ReactNode } from "@tanstack/react-router";
import { Flex, Table } from "antd";
import type { AnyObject } from "antd/es/_util/type";
import type { ColumnType } from "antd/es/table";
import { type ComponentProps, useMemo } from "react";
import { useSearch } from "./useSearch";

export type CoolTableProps<T extends AnyObject> = ComponentProps<
	typeof Table<T>
> & {
	searcheable?: boolean;
	ellipsis?: boolean;
	rightActions?: ReactNode;
	rowActions?: CoolTableColumnType<T>[number];
};

type CoolColumnType<T> = Omit<ColumnType<T>, "dataIndex"> & {
	dataIndex: Extract<keyof T, string>;
};

export type CoolTableColumnType<T> = CoolColumnType<T>[];

export const CoolTable = <T extends AnyObject>(props: CoolTableProps<T>) => {
	const { filteredData, searchElement } = useSearch(
		props.dataSource,
		props.searcheable ?? true,
	);
	const allColumns = useMemo(() => {
		return [
			...(props.columns ?? []).map((x) => {
				x.ellipsis = props.ellipsis ?? true;
				return x;
			}),
			...(props.rowActions ? [props.rowActions] : []),
		];
	}, [props.rowActions, props.columns, props.ellipsis]);
	return (
		<Flex gap={16} vertical>
			<Flex gap={16}>
				{searchElement}
				{props.rightActions}
			</Flex>
			<Table
				{...props}
				columns={allColumns}
				dataSource={filteredData}
				rowKey={props.rowKey ?? "id"}
				size={props.size ?? "small"}
				bordered={props.bordered ?? true}
			/>
		</Flex>
	);
};
